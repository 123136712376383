@import url('https://use.fontawesome.com/releases/v5.7.2/css/all.css');
@import './colors.scss';

.grafo-flux {
  width: 75%;
  min-height: 400px;
}

.grafo-informacoes {
  width: 20%;
}

.grafo-math {
  width: 100%;
}

.grafoDetailsRoot h2 {
  font-size: 20px;
  font-weight: bold;
  color: var(--my-primary);
}

.grafoDetailsCNPJ {
  font-size: 12px;
  position: relative;
  top: -5px;
  color: var(--my-primary);
}

.grafoDetailsContent {
  // padding-bottom: 5px;
  // margin-top: 3px;
}

.grafoDetailsContent .label {
  width: 130px;
  font-size: 12px;
  display: inline-block;
}

.grafoDetailsContent .value {
  font-weight: bold;
  margin-left: 10px;
  font-size: 12px;
}

.grafoDetailsDivisor {
  border-top: 1px dashed #0000004d;
  margin-top: 20px;
  margin-bottom: 0px;
}
.grafoDetailsDivisor span {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 800;
}

.grafoDetailsHeader {
  display: block;
}
.grafoDetailsIcon {
  float: left;
  width: 60px;
  height: 50px;
  padding: 0px;
}
.grafoDetailsHeaderContent {
}

.grafoDetailsPFQtd {
  text-transform: uppercase;
  color: var(--my-primary);
  font-size: 12px;
  position: relative;
  top: -7px;
}

.grafoDetailsPFQtd span {
  font-weight: 700;
  padding: 0 5px;
}

.grafoDetailsPF-title {
  color: var(--my-primary);
  font-size: 10px;
  padding: 5px;
  display: inline-block;
  text-transform: uppercase;
}

.empresasPF-details {
  font-size: 12px;
  border-bottom: 1px solid var(--my-primary);
  padding-bottom: 5px;
  margin-top: 5px;
}

.empresatipo {
  background: var(--my-primary);
  font-size: 10px;
  font-weight: 800;
  text-align: center;
  margin: 10px;
  max-width: 60px;
  margin-left: 0;
  text-transform: uppercase;
  color: white;
  border-radius: 5px;
  padding: 0;
  margin: 3px;
  height: 20px;
  padding-top: 3px;
}

.empresaName {
  font-size: 13px;
  font-weight: 600;
  margin-top: 2px;
}

.compreende {
  margin: 0;
  font-size: 10px;
}
