@import './colors.scss';

.cardbase {
  background: white;
  width: 100%;
  margin: 10px 24px 0 0;
  padding: 20px;
  padding-right: 40px;
  margin-bottom: 20px;
}

.cardbase-title {
  font-size: 12px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);
  background: linear-gradient(90deg, #9aca3c 0%, transparent);
  padding: 6px 9px;
}

.cardbase-body {
  padding: 10px;
}

.field-divisor {
  margin: 14px 0 10px 0;
  font-size: 10px;
  margin-left: 17px;
  text-transform: uppercase;
  font-weight: 700;
  width: 90%;
  background: linear-gradient(45deg, #8ec7453b, transparent);
}

.field-root {
  margin: 5px 30px 5px 0;
}

.field-value {
  font-size: 11px;
  padding: 0px;
  margin: 0px;
  margin-top: 2px;
  font-weight: 400;
}

.field-label {
  font-size: 10px;
  color: #00000096;
  text-transform: uppercase;
  font-weight: 400;
}

.field-warning {
  padding: 1em;
  margin-top: 1em;
  color: #e1e1e1;
  font-weight: 700;
  display: inline-block;
  margin-top: 10px;
  font-size: 2em;
}

.socio-box {
  border-radius: 5px;
  background: #f7f7f7;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  padding-top: 10px;
}
.socio-cnpj {
  font-size: 12px;
  // margin-bottom: 5px;
}
.socio-data_entrada_sociedade {
  font-size: 12px;
  margin-bottom: 5px;
}
.socio-nome {
  font-size: 1em;
  font-weight: 700;
}
