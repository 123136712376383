.question {
  font-size: larger;
}

.invalidValue {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
