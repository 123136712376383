.value {
  font-size: 11px;
  color: #002a40;
}

.label {
  font-size: 10px;
  color: #002a40;
  text-transform: uppercase;
}
