.rootContainer {
  background-color: #ffffff;
}

.headerContainer {
  background-color: #ffffff;
  font-size: 24px;
}

.title {
  color: var(--my-primary);
  font-size: 20px;
}

.subtitle {
  color: var(--my-primary);
  font-size: 12px;
}
